import $ from 'jquery';
require('popper.js');
require('bootstrap');
require("slick-carousel");
import noUiSlider from "nouislider" ;
require("lightbox2");
require("@fancyapps/fancybox");
global.jQuery = $;

$("#loader").hide();
$(function () {
    $("[data-toggle='collapse']").collapse();
    $("[data-slick]").slick({
        // normal options...
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    dots: false
                }
            }
        ]
    });
    if ($("form[name='filter']").length) {
        let filter_price = document.getElementById('filter_price');
        let filter_price_0 = document.getElementById('filter_price_0');
        let filter_price_1 = document.getElementById('filter_price_1');
        let inputs = [filter_price_0, filter_price_1];
        let startval = [filter_price_0.value, filter_price_1.value];
        let max = filter_price_1.getAttribute('max');
        noUiSlider.create(filter_price, {
            start: startval,
            connect: true,
            range: {
                'min': [0],
                'max': [parseInt(max)]
            },
            step: 1000,
            format: {
                to: function (value) {
                    return parseInt(value);
                },
                from: function (value) {
                    return parseInt(value);
                }
            }
        });
        filter_price.noUiSlider.on('update', function (values, handle) {
            inputs[handle].value = values[handle];
        });
        filter_price.noUiSlider.on('set', function (values, handle) {
            $("form[name='filter']").trigger("change");
        });
        inputs.forEach(function (input, handle) {

            // setSliderHandle(handle, this.value);
            var r = [null, null];
            r[handle] = input.value;
            console.log(r);
            filter_price.noUiSlider.set(r);
            input.addEventListener('change', function (i, value) {
                console.log(i);
                console.log(value);
                // var r = [null, null];
                r[handle] = this.value;
                console.log(r);
                filter_price.noUiSlider.set(r);
                $("form[name='filter']").trigger("change");
            });
        });
        $("form[name='filter']").on("change", function () {
            let url_parts = [];
            let url_base = $(this).find("[name='base']").val();
            let formID = $(this).find("[name='target']").val();

            $("#loader").show();
            $(this).find("input[type='radio']:checked").each(function (i, ind) {
                //console.log($(this).val());
                url_parts.push($(this).val());
            });
            $.get(url_base, $(this).serialize(), function (response) {
                let content = $(response).find("#"+formID);
                console.log(content);
                let pagetitle = $(response).filter("title");
                $("#"+formID).replaceWith(content);
                if (url_parts.length > 0) {
                    window.history.pushState("object", pagetitle, url_base + "/" + url_parts.join("_"));
                } else {
                    window.history.pushState("object", pagetitle, url_base);
                }
            });
            $("#loader").hide();
        });
        $("form[name='filter'] [data-reset]").on("click", function (e) {
            e.preventDefault();
            let target = $(this).data("reset");
            //console.log(target);
            $("input[name='" + target + "']:checked").prop("checked", false);
            $("form[name='filter']").trigger("change");
        })
    }
    if ($("form[name='filter-material']").length) {
        $("form[name='filter-material']").on("change", function () {
            let target = $(this).data('catalogue');
            console.log(target);
            let url_base = $(this).find("[name='base']").val();
            $("#loader").show();
            $.get(url_base, $(this).serialize() + "&isNaked=1", function (response) {
                let content = $(response).find(".catalogue_material");
                console.log(content);
                //let pagetitle = $(response).filter("title");
                $("#" + target).replaceWith(content);
            });
            $("#loader").hide();
        });
        $("form[name='filter-material'] [data-reset]").on("click", function (e) {
            e.preventDefault();
            let target = $(this).data("reset");
            console.log(target);
            $("input[name='" + target + "']:checked").prop("checked", false);
            $("form[name='filter-material']").trigger("change");
        })
    }
});
$(document).on("click", ".loadmore", function (e) {
    e.preventDefault();
    $("#loader").show();
    let target = $(this).parent("div");
    let contt = $(this).data("content");
    $.get($(this).attr("href"), function (response) {
        let content = $(response).find(contt).html();
        console.log(content);
        //let pagetitle = $(response).filter("title");
        $(target).replaceWith(content);
    });
    $("#loader").hide();
});

$(document).on("submit","form[name='adminForm']",function(e){
    e.preventDefault();
    e.stopPropagation();
    var formValid = true;
    let form = $(this);
    let modal = $("#modal"+form.prop("id"));
    form.find("input").each(function(){
       $(this).removeClass('is-invalid');
       if($(this).is('[required]') && $(this).val().length==0) {
           $(this).addClass('is-invalid');
           formValid = false;
       }
    });

    if(formValid){
        $.post(form.attr("action"),form.serialize()+"&isNaked=1&json=1",function (response) {
            console.log(response);
            console.log(modal);
            form.find(".form-response").html(response);
            if($(response).find('#nc_error').length>0) {
            }else{
                setTimeout(function(){
                    form[0].reset();
                    form.find(".form-response").empty();
                    $("#modal"+form.prop("id")).modal('hide');
                },3000);
            }
        });
    }
    return false;
});